.chart-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    width: 100%;
    overflow: hidden;
  }
  
  .pie-chart {
    width: 100% !important;
    max-width: 400px; /* Ajusta este valor según sea necesario */
    height: auto !important;
  }

  .indicadores-container{
    background-color: #189D98;
    border-radius: 30px;
  }