/* .half-circle {
    position: absolute;
    top: -70px;
    left: 50%;
    width: 100%;
    height: 40%;
    background-color: rgba(0, 0, 0, 0.1);
    clip-path: polygon(0% 15%, 10% 100%, 30% 15%, 50% 100%, 70% 15%, 90% 100%, 100% 15%);
    transform: translateX(-50%);
  }
   */

   .half-circle {
    position: absolute;
    top: -209px; /* Ajusta la posición para eliminar el espacio superior */
    left: 50%;
    width: 100%;
    height: 50%; /* Ajusta la altura para ser más ancha */
    background-color: rgba(0, 0, 0, 0.1);
    clip-path: polygon(0% 30%, 12% 100%, 38% 30%, 50% 100%, 62% 30%, 88% 100%, 100% 30%);
    transform: translateX(-50%);
  }
  
  
  .welcome-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 50vh; /* Ajusta el tamaño según sea necesario */
    text-align: center;
    color: white;
    position: relative; 
  }
  
  .welcome-row {
    width: 100%;
    text-align: center;
  }
  
  .welcome-col {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;
  }
  
  .avatar-col {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
