.gradient-hr {
    height: 1px;
    width: 100%;
    margin: 0 auto;
    background: linear-gradient(to right, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.8), rgba(24, 144, 255, 0.2));
    border: none;
    margin-bottom: 1rem;
  }
  .paint-splash {
    position: absolute;
    top: -100px; /* Ajusta según sea necesario para centrarlo verticalmente */
    left: 0;
    right: 0;
    height: 300px; /* Altura suficiente para cubrir el área deseada */
    background-color: rgba(38, 12, 12, 0.2);
    border-radius: 50%;
    transform: rotate(-10deg); /* Ajusta la rotación si es necesario */
    z-index: -1;
    clip-path: ellipse(70% 40% at 50% 50%); /* Forma elíptica para una apariencia más orgánica */
  }
  
  