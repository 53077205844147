/* src/components/Home.css */
.navigation-container {
    text-align: center;
    padding: 20px;
    background-color: #e6dff3e5;
    border-radius: 10px;
  }
  
  .navigation-image {
    border-radius: 20px;
    margin-bottom: 20px;
  }
  
  .navigation-button {
    width: 100%;
    border-radius: 2rem;
    background-color: black;
    border: 1px solid white
  }
  .container_Tipos {
    position: relative;
    min-height: 100vh;
    background-color: #1e2b49;
    border-radius: 20px;
    overflow: hidden; /* Asegura que el contenido no se desborde del contenedor */
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  